body {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

body::-webkit-scrollbar {
    /* Chrome, Safari, Opera*/
    display: none;
}

body::-webkit-scrollbar-thumb {
    /* Chrome, Safari, Opera*/
    background: #eef1fd;
}
.none-scroll {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}
.none-scroll::-webkit-scrollbar {
    /* Chrome, Safari, Opera*/
    display: none;
}
.ql-editor{
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    max-height: auto !important;
}
.ql-editor::-webkit-scrollbar{
    display: none;
}
.ql-editor{
    padding: 16px !important;
}
.ql-editor > p > img{
    width: 100% !important;
}

.ql-editor > p > span > img{
    width: 100% !important;
}
.show-scrollbar ::-webkit-scrollbar {
    display: block !important;
}

.board-container {
    width: 100%;
}

.emoji {
    background: transparent;
}
.slick-dots {
    bottom: 16px !important;
}

.slick-dots li.slick-active button:before {
    color: #3250d2 !important;
}

#editor {
    position: relative !important;
}

.emoji-picker-react {
    position: absolute !important;
    z-index: 100;
    top: 56px;
    right: 36px;
    display: none !important;
}
.scroll-table::-webkit-scrollbar{
    display: none;
}
.scroll-table::-webkit-scrollbar-thumb {
    /* Chrome, Safari, Opera*/
    background: #3250d255;
}
.scroll-table-green::-webkit-scrollbar{
    display: none;
    
}
.scroll-table-green::-webkit-scrollbar-thumb {
    /* Chrome, Safari, Opera*/
    background: #3250d255;
    position: fixed;
    bottom: 0;
}
.scroll-css {}

.scroll-css::-webkit-scrollbar {
    width: 10px;
}

.scroll-css::-webkit-scrollbar-thumb {
    background-color: #3250d2;
}

.scroll-css::-webkit-scrollbar-track {
    background-color: #ffffff;
}
#ALLAT_MOBILE_PAY{
    height: 100vh !important;
}
.banner-img{
    width: 1150px;
    height: 575px;
}
.enrolment-banner-img{
    width: 1000px;
    height: 250px;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {}

.menu-icon {
    width: auto;
    height: 1.5rem;
    margin: 0 auto;
    position: relative;
    padding: 0.6rem;
}

#editor {
    margin: 24px;
}

#board-content {
    width: 2.2rem;
    font-size: 2rem;
    margin: 1.5rem 0 0.5rem 0;
}

#board-text {
    font-size: 12px;
}

.subtype-container::-webkit-scrollbar {
    display: none;
}
.viewer > div > div > div.ql-editor > p > a > img{
    max-width: 1000px !important;
}
.viewer > div > div > div.ql-editor > p > img{
    max-width: 1000px !important;
}
.comment-img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin-right: 16px;
}

.note {
    width: 100%;
    margin: 16px 0;
}

div.note>img {
    width: 100%;
}

div.note>p>img {
    width: 100% !important;
}

.pointer {
    cursor: pointer;
}

.bar {
    flex: auto;
    border: 1px solid #4CDAD8;
    height: 2px;
    background: #4CDAD8;
}

.smaller-margin-right-content {
    margin: 0 1rem 0 auto;
}
.width-100{
    width: 100% !important;
}
.card-img {
    width: 100% !important;
    height: 320px;
    background: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-blend-mode: multiply;
}
.theme-img{
    width: 150px;
    background:#fff;
}
.video-img{
    width: 90%;
height:320px;
background:#fff;
background-size: cover;
background-repeat: no-repeat;
background-position: center center;
background-blend-mode: multiply;
margin: 18px auto;

}
#ALLAT_MOBILE_PAY{
    position: fixed !important;
}
.slick-next{
    display: none !important;
}
.slick-prev{
    display: none !important;
}

.master-dropdown-content{
    position: fixed;
    display: flex;
    top:6rem;
    left:0;
    width:100vw;
    background: #fff;
    box-shadow: 0px 12px 12px #00000029;
    border-top:1px solid #ccc;
    padding:1rem 0;
    animation: fadein 0.5s;
}
.service-dropdown-content{
    position: absolute;
    display: none;
    top:6.12rem;
    right:-75px;
    background: #fff;
    color: #707070 !important;
    font-weight: normal;
    font-size: 15px;
    box-shadow: 0px 12px 12px #00000029;
    border-top:1px solid #ccc;
    animation: fadein 0.5s;
    width: 150px;
    z-index: 99;
}
.service-dropdown-content  div{
    padding: 8px !important;
}
.prevArrow {
    position: absolute;
    top: 260px;
    left: 12px;
    z-index: 2;
    cursor: pointer;
    font-size: 28px;
    padding: 5px 5px 0px 5px;
    border-radius: 50%;
    background: #aaaaaa55;
    color: #fff !important;
}

.nextArrow {
    position: absolute;
    top: 260px;
    right: 12px;
    z-index: 2;
    cursor: pointer;
    font-size: 28px;
    padding:5px 5px 0px 5px;
    border-radius: 50%;
    background: #aaaaaa55;
    color: #fff !important;

}
.merchandise-content {
    width: 20.83%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    box-shadow: 4px 12px 30px 6px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    height: 300px;
  }
  @media screen and (max-width:1350px) {
    .merchandise-content {
        width: 30.15%;
        height: 32.15vw;
      }
  }
@media screen and (max-width:1200px) {
    .video-img{
        height: 28.266666666vw;
    }
    .viewer > div > div > div.ql-editor > p > a > img{
        width: 100% !important;
    }
    .viewer > div > div > div.ql-editor > p > img{
        width: 100% !important;
    }
}

@media screen and (max-width:1100px) {
    .card-img {
        height: 28.8vw;
    }
}
@media screen and (max-width:1000px) {
    .theme-img{
        width:15vw;

    }
    .enrolment-banner-img{
        width: 100vw;
        height: 25vw;
    }
}
@media screen and (max-width:1150px) {
    .banner-img{
        width: 100vw;
        height: 50vw;
    }
    .slick-dots {
        bottom: 1vw !important;
    }
    .prevArrow {
        top: 22vw;
    }
    .nextArrow {
        top: 22vw;
    }
}


@media screen and (max-width:1000px) {
    #editor {
        margin: 16px auto;
        width: 90%;
    }
    
}
@media screen and (max-width:850px) {
    
    .merchandise-content {
        width: 47.5%;
        height: 50.25vw;
      }
}
@media screen and (max-width:700px) {
    .theme-img {
        width: 30vw;
    }
   
}
@media screen and (max-width:650px) {
    .merchandise-content {
        width: 100%;
        height: 30vw;
        flex-direction: row-reverse;
      }
   
}
@media screen and (max-width:600px) {
    .video-img{
        height: 50vw;
        margin: 5vw auto;
    }
    .card-img {
        height: 52.2222222222vw;
    }

    

    .progress-bar {
        appearance: none;
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        right: 0;

    }

    .progress-bar::-webkit-progress-bar {
        background: #f0f0f0;
        border-radius: 0;
    }

    .progress-bar::-webkit-progress-value {
        border-radius: 0;
        background: #4CDAD8;
    }
}

@media screen and (max-width:600px) {
    .smaller-margin-right-content {
        margin: 0 0.5rem 0 auto;
    }
}

.dark-mode {
    background: #222 !important;
    color: #fff !important;
}